html {
  --theme-color: #0f5ead;;
}

.log-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.jovan-color {
  color : var(--theme-color);
}

.table > :not(caption) > * > * {
  /*padding: 0.5rem 0.5rem;*/
  /*background-color: var(--bs-table-bg);*/
   border-bottom-width: 0px;
  /*box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);*/
}
@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.btn-log {
  width: 220px;
}